<template>
  <span class="px-3">
    <EditOnClick v-model="value" :type="row.type" :editable="row.editable" />
    <button
      class="btn btn-secondary btn-sm btn-no-r"
      :title="$t(`orderProducts.addItem`)"
      v-on:click="show(false, true)"
      :disabled="extraRow > 1"
    >
      <BaseIcon name="plus" />
    </button>
    <button
      class="btn btn-secondary btn-sm btn-no-r btn-no-l"
      :title="$t(`orderProducts.changeItem`)"
      v-on:click="show(true, true)"
      :disabled="extraRow === 3"
    >
      <BaseIcon name="change" />
    </button>
    <button
      class="btn btn-secondary btn-sm btn-no-l"
      :title="$t(`orderProducts.returnItem`)"
      v-on:click="show(true, false)"
      :disabled="extraRow === 3"
    >
      <BaseIcon name="trash" />
    </button>
  </span>
  <OrderChange
    ref="orderChangeModal"
    :model-value="id"
    v-if="data !== undefined"
    v-model:products="products"
    v-model:default-delivery="delivery"
    v-model:remove-element="removeElement"
    v-model:warehouse="data.warehouse_id"
    v-model:webshop-id="data.webshop_id"
    v-model:shop-id="data.shop_id"
    v-model:delivery-status="deliveredStatus"
    v-model:add-element="addElement"
  />
</template>
<script>
import { defineAsyncComponent } from "vue";
import EditOnClick from "@/components/inputs/EditOnClick";
import OrderChange from "@/components/modals/OrderChange";
import http from "@/modules/http";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "OrderProducts",
  components: { OrderChange, EditOnClick, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
    extraRow: String,
  },
  data() {
    return {
      value: this.modelValue,
      data: undefined,
      products: null,
      delivery: undefined,
      addElement: false,
      removeElement: false,
      deliveredStatus: null,
      shop: undefined,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    show(remove, add) {
      this.removeElement = remove;
      this.addElement = add;
      http.fetch("/orders/" + this.id).then((data) => {
        if (
          data.orderPackages.length < 1 ||
          data.orderPackages[0].orderDeliveries.length < 1
        ) {
          this.delivery = null;
        } else {
          this.delivery = data.orderPackages[0].orderDeliveries[0];
        }

        this.products = data.orderItems;
        this.deliveredStatus = data.delivered_status;

        this.data = {
          webshop: data.webshop,
          webshop_id: data.webshop_id,
          warehouse_id: data.warehouse_id,
          shop_id: data.shop_id,
          type: data.business_name === null ? 0 : 1,
          email: data.email,
          phone: data.phone,
          first_name: data.first_name,
          last_name: data.last_name,
          tax_code: data.tax_code,
          eu_tax_code: data.eu_tax_code,
          business_name: data.business_name,
          country_id: data.country_id,
          country: data.country,
          invoice_post_code: data.invoice_address.post_code,
          invoice_city: data.invoice_address.city,
          invoice_street: data.invoice_address.street,
          invoice_house: data.invoice_address.house,
          delivery: this.delivery,
        };
        setTimeout(() => {
          this.$refs.orderChangeModal.showModal();
        }, 100);
      });
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
